<template>
  <div>
    <b-row class="match-height d-flex align-items-center">
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="searchData.keyword"
            placeholder="Müşteri Adı, Araç Kodu, Plaka, Şase..."
            @keydown.enter="searchAction"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="searchAction"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          :to="$route.path + '/search'"
        >
          <FeatherIcon icon="PlusIcon" /> Oluştur
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'SearchBar',
  components: {
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  },
  props: {
    searchAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      detailFilter: false,
    }
  },
  computed: {
    searchData() {
      return this.$store.getters['rentalVehiclePark/searchData']
    },
  },
}
</script>
